:root {
  --primary-color: #2DB75B;
  --light-green: #E1F6E8;
  --primary-font-family: 'Raleway', sans-serif;
  --secondary-font-family: 'Lora', serif;;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  font-family: 'Raleway', sans-serif;
}

*{
  box-sizing: border-box;
}


p {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}

h2 {
  font-size: 36px;
  font-family: 'Raleway', sans-serif;
}

button, span, input {
  font-family: 'Raleway', sans-serif;
}


@media screen and (max-width: 963px) {

}

@media screen and (max-width: 600px) {
  p {
    font-size: 15px;
  }

  h2 {
    font-size: 26px !important;
  }
}
