#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.spinner-chase {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
  animation: spinner-chase 2.5s infinite linear both;
}

.chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: chase-dot 2.0s infinite ease-in-out both; 
  &:before {
      content: '';
      display: block;
      width: 25%;
      height: 25%;
      background-color: #2DB75B;
      border-radius: 100%;
      animation: chase-dot-before 2.0s infinite ease-in-out both; 
  }

  &:nth-child(1) { 
      animation-delay: -1.1s; 
      &:before{
          animation-delay: -1.1s;
      }
  }
  &:nth-child(2) { 
      animation-delay: -1.0s;
      &:before{
          animation-delay: -1.0s;
      }
  }
  &:nth-child(3) { 
      animation-delay: -0.9s;
      &:before{
          animation-delay: -0.9s;
      } 
  }
  &:nth-child(4) { 
      animation-delay: -0.8s; 
      &:before{
          animation-delay: -0.8s;
      } 
  }
  &:nth-child(5) { 
      animation-delay: -0.7s; 
      &:before{
          animation-delay: -0.7s;
      } 
  }
  &:nth-child(6) { 
      animation-delay: -0.6s; 
      &:before{
          animation-delay: -0.6s;
      }
  }
}

@keyframes spinner-chase {
  100% { 
      transform: rotate(360deg); 
  } 
}

@keyframes chase-dot {
  80%, 100% { 
      transform: rotate(360deg); 
  } 
}

@keyframes chase-dot-before {
  50% {
      transform: scale(0.4); 
  } 
  100%, 0% {
      transform: scale(1.0); 
  } 
}

.swal-wide{
    width: 650px !important;
}
